import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import { SlotId } from './config';
import { ReelSetType } from './gql/d';
import { Icon, LineSet } from './slotMachine/d';

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface IMetadata {
  type: string;
  data: {
    messageTitle: string;
    messageBody: string;
  };
}

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      isBonus: boolean;
      bonuses: UserBonus[];
      features: {
        isFreeSpins: boolean;
        mystery: { changeTo: SlotId };
      };
    };
    createdAt: string;
    updatedAt: string;
  };
  paylines: {
    lineId: number;
    winPositions: number[];
    amount: number;
  }[];
  balance: {
    placed: {
      amount: number;
      currency: string;
      metadata?: IMetadata;
    };
    settled: {
      amount: number;
      currency: string;
      metadata?: IMetadata;
    };
  };
}
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
  additionalReelSets: SlotId[][];
};
export type UserBonus = {
  bonusId: string;
  id: string;
  isActive: boolean;
  currentRound: number;
  betId: string;
  rounds: number;
  reelSetId: string;
  status: BonusStatus;
  gameMode: GameMode;
  isFreeBet?: boolean;
  totalWinAmount: number;
  data: UserBonusData;
  coinAmount: number;
  coinValue: number;
};
export type UserBonusData = {
  frbReferenceId: string | null;
};
export enum GameMode {
  REGULAR,
  FREE_SPINS,
  BUY_FEATURE_FREE_SPINS,
  BUY_FEATURE,
  FREE_ROUND_BONUS,
}
export const reelSets = {
  [GameMode.REGULAR]: '4693431f-933f-4943-9710-418d6e59ef0e',
  [GameMode.FREE_SPINS]: '5976f321-ac72-4e57-986c-b47a55eb3248',
  [GameMode.BUY_FEATURE_FREE_SPINS]: '069d2bda-6300-4cb6-9c97-f2779bd1c919',
  [GameMode.BUY_FEATURE]: '208d6fca-5869-4d0d-8b1c-0e7b730aa4d6',
  [GameMode.FREE_ROUND_BONUS]: '4693431f-933f-4943-9710-418d6e59ef0e',
};
export const bonusesId = {
  [GameMode.BUY_FEATURE]: '8d9d2b4d-4802-47e1-b606-e79e31204776',
  [GameMode.FREE_SPINS]: '081fa732-5e0c-4b6a-a26f-662caede7d32',
  [GameMode.BUY_FEATURE_FREE_SPINS]: 'ba4f8ead-4f6d-4e8d-bb77-782c52c72798',
  [GameMode.FREE_ROUND_BONUS]: '33e999e6-9f63-4cf8-830c-fc54a058c5b7',
};
export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
}
export interface GetUserBonusesInput {
  id: string;
  status: BonusStatus;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: string;
  currentSpin: string;
};

export type MessageBannerProps = {
  title?: string;
  titlePosition?: number;
  titleStyles?: PIXI.TextStyle;
  subtitle?: string;
  subtitlePosition?: number;
  subtitleStyles?: PIXI.TextStyle;
  btnText?: string;
  additionalText?: string;
  additionalPosition?: number;
  additionalStyles?: PIXI.TextStyle;
  preventDefaultDestroy?: boolean;
  callback?: () => void;
  onInitCallback?: () => void;
};

export type ReplayFreeSpinBets = {
  id: string;
  reelSetId: string;
};

export enum EventTypes {
  ANTICIPATION_STARTS = 'anticipationStarts',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_FREE_SPINS_TITLE = 'createFreeSpinsTitle',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  END_RETRIGGER_FEATURE = 'endRetriggerFeature',
  FORCE_STOP_REELS = 'forceStopReels',
  HIDE_WIN_LINES = 'hideWinLines',
  HIDE_COINS = 'hideCoins',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  HANDLE_IS_ACTIVE_FREE_SPINS_GAME = 'handleIsActiveFreeSpinsGame',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REPLACE_MYSTERY_SLOTS = 'replaceMysterySlots',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_FREE_SPINS_TITLE = 'removeFreeSpinsTitle',
  REMOVE_ANIMATOR = 'removeAnimator',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_RETRIGGER_FEATURE = 'startRetriggerFeature',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  SHOW_WIN_LINES = 'showWinLines',
  SHOW_TINT = 'showTint',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SHOW_WIN_LABEL = 'showWinLabel',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_POPUP_BG = 'openBuyFeaturePopupBg',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  OPEN_BUY_FEATURE_CONFIRM_POPUP_BG = 'openBuyFeatureConfirmPopupBg',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_POPUP_BG = 'closeBuyFeaturePopupBg',
  CLOSE_BUY_FEATURE_CONFIRM_POPUP = 'closeBuyFeatureConfirmPopup',
  CLOSE_BUY_FEATURE_CONFIRM_POPUP_BG = 'closeBuyFeatureConfirmPopupBg',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  SOUND_INITIALIZED = 'soundInitialized',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoplay',
  FORCE_CLOSE_BUYFEATURE = 'forceStopBuyFeature',
  SET_LAST_BET_RESULT_AFTER_FREE_SPINS = 'setLastBetResultAfterFreeSpins',
  SET_REPLAY_BET_ID = 'setReplayBetId',
  START_FREE_ROUND_BONUS = 'startFreeRoundBonus',
  END_FREE_ROUND_BONUS = 'endFreeRoundBonus',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  FREE_ROUND_BONUS_EXPIRED = 'freeRoundBonusExpired',
  OPEN_POPUP = 'openPopup',
  CLOSE_POPUP = 'closePopup',
  END_MODE_CHANGE_FADE = 'endModeFadeChange',
}

declare global {
  interface Window {
    __ENV__: {
      APP_VERSION: string;
      APP_NAME: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      REACT_APP_TRANSLATE_URL: string;
      ENV: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    Howler: {
      _spriteBaseUrl: string;
    };
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}
export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
}

const font = 'NotoSans-SemiCondensedBold';
const GRADIENT_GOLD_TEXT_COLOR_CONFIG = {
  fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
};

export const buyFeatureTitleStyle = {
  fontSize: 60,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: '900',
  leading: '',
  align: 'center',
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  padding: 9,
};

export const buyFeatureTitleStyleB = {
  fontSize: 40,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: '900',
  leading: '',
  align: 'center',
  fill: ['#00ffff', '#0088ff', '#0000ff'],
  fillGradientStops: [0.3, 0.65, 0.8],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
  padding: 6,
};

export const totalCostTextStyle = {
  fontSize: 50,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: 'bold',
  leading: '',
  fill: ['#0088ff', '#0000ff', '#000088'],
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
  padding: 8,
};

export const totalCostTextAmountStyle = {
  fontSize: 50,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: 'bold',
  leading: '',
  lineHeight: 50,
  fill: ['#ff8800', '#ff0000', '#880000'],
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
  padding: 8,
};

export const amountTextStyle = {
  fontSize: 50,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: 'bold',
  leading: '',
  lineHeight: 50,
  fill: ['#0088ff', '#0000ff', '#000088'],
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
  wordWrap: true,
  wordWrapWidth: 240,
  breakWords: true,
  padding: 8,
};

export const betValueStyle = {
  fontSize: 50,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: 'bold',
  leading: '',
  lineHeight: 50,
  fill: ['#ff8800', '#ff0000', '#880000'],
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
  padding: 8,
};

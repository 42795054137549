const font = 'NotoSans-SemiCondensedBold';

export const titleStyle = {
  fontSize: 60,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
  letterSpacing: 0,
  fontWeight: 'bolder',
  lineJoin: 'round',
  padding: 9,
};

export const subtitleStyle = {
  fontSize: 50,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 2,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
  letterSpacing: 0,
  lineJoin: 'round',
  padding: 8,
};
export const additionalStyle = {
  fontSize: 40,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#ff8800', '#ff0000', '#880000'],
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
  lineJoin: 'round',
  letterSpacing: 0,
  padding: 6,
};

export const winTitleStyle = {
  fontSize: 80,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
  letterSpacing: 0,
  fontWeight: 'bolder',
  lineJoin: 'round',
  padding: 12,
};

export const winSubtitleStyle = {
  fontSize: 120,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
  letterSpacing: 0,
  fontWeight: 'bolder',
  lineJoin: 'round',
  padding: 18,
};

export const btnStyle = {
  fontSize: 40,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#ff8800', '#ff0000', '#880000'],
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
  letterSpacing: 0,
  lineJoin: 'round',
  padding: 6,
};
